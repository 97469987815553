
<section style="min-height:500px" class="d-md-block container-pp-products-sm" id="products">
  <div class="container" id="content-pp-products">
    <div class="row justify-content-center">
      <div v-for="(product, index) in products" :key="index"
           class="col-6 col-sm-6 col-md-3 product-item">
        <div class="card h-100 d-flex flex-column justify-content-center" @click="showProduct(product)">
          <div class="card-header text-center">
            <img class="img-product-card-wrapper"
                 :src="product.producto_imagen.public_path"
                 :alt="product.producto_imagen.alt" :width="119" :height="117" loading="lazy">
          </div>
          <div class="card-body text-center">
            <div class="align-items-center card-title product-title-card align-items-center">
              {{ product.title }}
            </div>
            <div style="min-height: 105px" class="small" v-html="formatHtmlQuotationMarks(product.dinero_al_instante)"></div>
            <div class="d-block">
              <button @click="showProduct(product)" class="btn btn-primary btn-orange">
                MÁS INFORMACIÓN
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot name="footer"></slot>
  </div>
</section>
